@font-face {
  font-family: "Lintel";
  src: local("Lintel"),
    url(./assets/fonts/Lintel-Regular.ttf) format("opentype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "LintelBold";
  src: local("Lintel"),
    url(./assets/fonts/lintel-extrabold.ttf) format("opentype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: "LintelMedium";
  src: local("Lintel"), url(./assets/fonts/lintel-medium.ttf) format("opentype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

canvas {
  outline: none;
  border: none;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  font-family: "Lintel", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}